import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// CSS LINKS
import '../../../css/layouts/modal.css';
import '../../../css/layouts/custom-modal/add-crypto.css';
import '../../../css/layouts/custom-modal/upgrade-to-premium.css';

// LAYOUTS COMPONENTS
import { Button } from '../Index';

// API COMPONENTS
import ApiService from '../../Server/ApiService';

// Import Notification
import Notification from '../../Layouts/Notification';
import PageBoard from '../Board/Index';

// Import Icon
import CloseIcon from '../../Icons/layouts/close-icon.svg';
import CheckIcon from '../../Icons/layouts/check-icon.svg';

const AddCrypto = () => {
    const navigate = useNavigate();

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [monthly, setMonthly] = useState([]);
    const [yearly, setYearly] = useState([]);
    const [redirectSubscription, setRedirectSubscription] = useState(false);
    const [openNotification, setOpenNotification] = useState(false);
    const [notificationSeverity, setNotificationSeverity] = useState("");

    useEffect(() => {
        loadSubscribe();
    }, []);

    const convertCommaToPoint = (numberWithComma) => {
        return numberWithComma.replace(',', '.');
    }

    const loadSubscribe = () => {
        setLoading(true);
        ApiService.get('/api/get/subscription')
            .then(response => {
                if (response.status === 200) {
                    const subscriptionId = response.data;

                    setMonthly(subscriptionId[0]);
                    setYearly(subscriptionId[1]);
                    setLoading(false);
                    setNotificationSeverity('info');
                    setMessage('Bouton non utilisable pour le moment.');
                    setOpenNotification(true);
                }else{
                    setNotificationSeverity('error');
                    setMessage(response.data.error);
                    setOpenNotification(true);
                    setLoading(true);
                }
            })
            .catch(error => {
                setNotificationSeverity('error');
                setMessage(error.error);
                setOpenNotification(true);
                setLoading(true);
            });
    }

    const handleCloseNotification = () => {
        setOpenNotification(false);
    };

    const handleSubscribe = (recurrence, offer, price) => {
        const data = [recurrence, offer, price];
    
        Cookies.set('subscription_data', JSON.stringify(data), { 
            expires: 0.1,
            domain: 'cryptoinitiate.fr',
            path: '/',
            sameSite: 'None',
            secure: true
        });
    
        setRedirectSubscription({ redirectSubscription: true });
    };

    const handleRedirectBack = () => {
        navigate(-1);
    };

    if(redirectSubscription){
        // const url = 'https://cryptoinitiate.fr/subscription';
        const url = "https://cryptoinitiate.fr/#pricing";
        window.open(url, '_blank');
    }

    return (
        <div>
            <Notification
                open={openNotification}
                handleClose={handleCloseNotification}
                severity={notificationSeverity}
                message={message}
            />

            <div className="wrapper-upgrade">
                <div className="modal-out">
                    <button onClick={() => handleRedirectBack()}><img src={CloseIcon} alt="Icon Out" /></button>
                </div>

                <div className="modal-title">
                    <span>Débloquez toutes les fonctionnalités avec <span>Premium</span></span>
                    <p>Abonnements sans condition d’engagement. Annulez à tout moment.</p>
                </div>
                
                <div className="container-modal-upgrade">
                    {/* Cards  */}
                    <div className="modal-card">
                        <div>
                            <div>
                                <span>Mensuel</span>
                                <p>Bénéficiez d’un accompagnement dédié dans l’investissement en crypto</p>
                            </div>
                            <div>
                                <div>
                                    <span>{monthly.stripe_price}</span>
                                    <span>€</span>
                                </div>
                                <div>
                                    <span>/mois</span>
                                </div>
                            </div>
                            <div>
                                <Button onClick={() => handleSubscribe(0, 'Investisseur Premium', convertCommaToPoint(monthly.stripe_price))}>S'abonner</Button>
                            </div>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <img src={CheckIcon} alt="Check Icon"/>
                                    <span>Accompagnement création portefeuille et plan d’investissement</span>
                                </li>
                                <li>
                                    <img src={CheckIcon} alt="Check Icon"/>
                                    <span>Ligne téléphonique dédiée avec l’un de nos experts</span>
                                </li>
                                <li>
                                    <img src={CheckIcon} alt="Check Icon"/>
                                    <span>Rapport mensuel investissements</span>
                                </li>
                                <li>
                                    <img src={CheckIcon} alt="Check Icon"/>
                                    <span>Accès à nos cours et analyses</span>
                                </li>
                                <li>
                                    <img src={CheckIcon} alt="Check Icon"/>
                                    <span>Accès à la sélection d’opportunités décelées par nos experts</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="modal-card">
                        <div>
                            <div>
                                <span>Annuel</span>
                                <p>Bénéficiez d’un accompagnement dédié dans l’investissement en crypto</p>
                            </div>
                            <div>
                                <div>
                                    <span>{yearly.stripe_price}</span>
                                    <span>€</span>
                                </div>
                                <div>
                                    <span>/an</span>
                                </div>
                            </div>
                            <div>
                                <Button onClick={() => handleSubscribe(1, 'Investisseur Premium', convertCommaToPoint(yearly.stripe_price))}>S'abonner</Button>
                            </div>
                            <div>
                                <div>
                                    <span>2 mois offerts</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <img src={CheckIcon} alt="Check Icon"/>
                                    <span>Accompagnement création portefeuille et plan d’investissement</span>
                                </li>
                                <li>
                                    <img src={CheckIcon} alt="Check Icon"/>
                                    <span>Ligne téléphonique dédiée avec l’un de nos experts</span>
                                </li>
                                <li>
                                    <img src={CheckIcon} alt="Check Icon"/>
                                    <span>Rapport mensuel investissements</span>
                                </li>
                                <li>
                                    <img src={CheckIcon} alt="Check Icon"/>
                                    <span>Accès à nos cours et analyses</span>
                                </li>
                                <li>
                                    <img src={CheckIcon} alt="Check Icon"/>
                                    <span>Accès à la sélection d’opportunités décelées par nos experts</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCrypto;