import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect, useState } from 'react';
import Slide, { SlideProps } from '@mui/material/Slide';

// Fonction pour la notification avec un style personnalisé
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const Notification = ({ open, handleClose, severity = "info", message }) => {
  const [vertical, setVertical] = useState('bottom');
  const [horizontal, setHorizontal] = useState('center');
  
  // useEffect(() => {
  //   setVertical({ vertical });
  //   setHorizontal({ horizontal });
  // }, []);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal,
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      key={vertical + horizontal}
      TransitionComponent={SlideTransition}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;