import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';

// CSS LINKS
import '../../../css/layouts/modal.css';
import '../../../css/layouts/custom-modal/my-wallet.css';

// LAYOUTS COMPONENTS
import EditWallet from './EditWallet';
import { Button, LoadingButton, Separated } from '../Index';
import TextShortener from '../TextShortener';
import PriceFormat from '../../Layouts/PriceFormat';

// ICON IMPORTS
import IconPlus from "../../Icons/modal/plus-icon.svg";
import IconCopy from "../../Icons/modal/copy-icon.svg";
import IconEdit from "../../Icons/modal/edit-icon.svg";
import IconTrash from "../../Icons/modal/trash-icon.svg";
import IconInterogation from "../../Icons/modal/interogation-icon.png";

// API COMPONENTS
import ApiService from "../../Server/ApiService";

// Import Notification
import Notification from '../Notification';

const MyWallet = ({ addWallet, openAddCrypto, refreshData }) => {
    const [state, setState] = useState({
        address: "",
        message: "",
        loading: false,
        openNotification: false,
        notificationSeverity: "",
        selectedWallets: [],
        isManaging: false,
        editingWallet: null,
        walletOptions: {
            addresses: [],
            wallets: [],
            currency_symbol: ''
        }
    });

    const getAddresses = async () => {

        setState(prevState => ({ ...prevState, loading: true }));

        try {
            const response = await ApiService.get(
                `/api/v4/get/addresses`,
                null,
                Cookies.get('token_auth'),
                null
            );

            if (response.status === 200) {
                const wallets = response.data.wallets || [];
                const addresses = response.data.addresses || [];
                const currency_symbol = response.data.currency_symbol || '';

                setState(prevState => ({
                    ...prevState,
                    walletOptions: { wallets, addresses, currency_symbol },
                    loading: false
                }));
            } else {
                if (response.info === 1) {
                    setState(prevState => ({
                        ...prevState,
                        notificationSeverity: 'info',
                        message: response.error,
                        openNotification: true,
                        loading: false,
                        walletOptions: { wallets: [], addresses: [], currency_symbol: response.currency_symbol },
                    }));
                } else {
                    setState(prevState => ({
                        ...prevState,
                        notificationSeverity: 'error',
                        message: response.error,
                        openNotification: true,
                        loading: false
                    }));
                }
            }
        } catch (error) {
            console.error('Error fetching wallets:', error);
            setState(prevState => ({
                ...prevState,
                notificationSeverity: 'error',
                message: 'Failed to fetch wallet data.',
                openNotification: true,
                loading: false
            }));
        }
    };

    const getWallets = async () => {

        setState(prevState => ({ ...prevState, loading: true }));

        try {
            const response = await ApiService.get(
                `/api/v3/get/user/wallet`,
                null,
                Cookies.get('token_auth'),
                null
            );

            if (response.status === 200) {
                const wallets = response.data.wallets || [];
                const currency_symbol = response.data.currency_symbol || '';

                setState(prevState => ({
                    ...prevState,
                    walletOptions: { addresses: state.walletOptions.addresses, wallets, currency_symbol },
                    loading: false
                }));
            } else {
                if (response.info === 1) {
                    setState(prevState => ({
                        ...prevState,
                        notificationSeverity: 'info',
                        message: response.error,
                        openNotification: true,
                        loading: false,
                        walletOptions: { wallets: [], addresses: state.walletOptions.addresses, currency_symbol: response.currency_symbol },
                    }));
                } else {
                    setState(prevState => ({
                        ...prevState,
                        notificationSeverity: 'error',
                        message: response.error,
                        openNotification: true,
                        loading: false
                    }));
                }
            }
        } catch (error) {
            console.error('Error fetching wallets:', error);
            setState(prevState => ({
                ...prevState,
                notificationSeverity: 'error',
                message: 'Failed to fetch wallet data.',
                openNotification: true,
                loading: false
            }));
        }
    };

    useEffect(() => {
        getAddresses();
    }, []);

    const handleCloseNotification = () => {
        setState(prevState => ({ ...prevState, openNotification: false }));
    };

    const handleUpdateWallet = async (updatedWallet, walletOrManualWallet) => {

        if(walletOrManualWallet === 0){
            try {
                const response = await ApiService.post(
                    `/api/v3/update/wallet/${updatedWallet.wallet_id}`,
                    {
                        'wallet_name': updatedWallet.addressName
                    },
                    Cookies.get('token_auth'),
                    null
                );
        
                if (response.status === 200) {
                    getWallets();
        
                    setState(prevState => ({
                        ...prevState,
                        notificationSeverity: 'success',
                        message: 'Portefeuille mis à jour avec succès.',
                        openNotification: true,
                        editingWallet: null
                    }));
                }else{
                    setState(prevState => ({
                        ...prevState,
                        notificationSeverity: 'error',
                        message: response.error,
                        openNotification: true,
                    }));
                }
            } catch (err) {
                setState(prevState => ({
                    ...prevState,
                    notificationSeverity: 'error',
                    message: err.error || "Erreur lors de la mise à jour.",
                    openNotification: true,
                    editingWallet: null
                }));
            }
        }else{
            if(updatedWallet.addressName !== updatedWallet.name_address){
                try {
                    const response = await ApiService.post(
                        `/api/update/address/${updatedWallet.address_id}`,
                        {
                            "_method": "PUT",
                            "name_address": updatedWallet.addressName
                        },
                        Cookies.get('token_auth'),
                        null
                    );
            
                    if (response.status === 200) {
                        getAddresses();
            
                        setState(prevState => ({
                            ...prevState,
                            notificationSeverity: 'success',
                            message: 'Portefeuille mis à jour avec succès.',
                            openNotification: true,
                            editingWallet: null
                        }));
                    }else{
                        setState(prevState => ({
                            ...prevState,
                            notificationSeverity: 'error',
                            message: response.error,
                            openNotification: true,
                        }));
                    }
                } catch (err) {
                    setState(prevState => ({
                        ...prevState,
                        notificationSeverity: 'error',
                        message: err.error || "Erreur lors de la mise à jour.",
                        openNotification: true,
                        editingWallet: null
                    }));
                }
            }else{
                setState(prevState => ({
                    ...prevState,
                    notificationSeverity: 'info',
                    message: 'Aucun changement effectué.',
                    openNotification: true,
                    editingWallet: null
                }));
            }
        }
    };    

    const deleteAddress = async (addressId) => {
        try {
            const response = await ApiService.delete(
                `/api/v3/delete/address/`+addressId,
                null,
                Cookies.get('token_auth'),
                null
            );

            if(response.status === 200){
                getAddresses();
                refreshData();

                setState(prevState => ({
                    ...prevState,
                    notificationSeverity: 'success',
                    message: response.success,
                    openNotification: true,
                    loading: false
                }));
            }else{
                setState(prevState => ({
                    ...prevState,
                    notificationSeverity: 'error',
                    message: response.error,
                    openNotification: true,
                    loading: false
                }));
            }
        } catch (err) {
            setState(prevState => ({
                ...prevState,
                notificationSeverity: 'error',
                message: err.error,
                openNotification: true,
                loading: false
            }));
        }
    }

    const deleteWallet = async (walletId) => {
        try {
            const response = await ApiService.delete(
                `/api/v3/delete/wallet/`+walletId,
                null,
                Cookies.get('token_auth'),
                null
            );

            if(response.status === 200){
                getWallets();
                refreshData();

                setState(prevState => ({
                    ...prevState,
                    notificationSeverity: 'success',
                    message: response.success,
                    openNotification: true,
                    loading: false
                }));
            }else{
                setState(prevState => ({
                    ...prevState,
                    notificationSeverity: 'error',
                    message: response.error,
                    openNotification: true,
                    loading: false
                }));
            }
        } catch (err) {
            setState(prevState => ({
                ...prevState,
                notificationSeverity: 'error',
                message: err.error,
                openNotification: true,
                loading: false
            }));
        }
    }  

    const handleToggleWallet = async (walletKey, currentActiveState, address_id, manual_wallet) => {
        const newActiveState = !currentActiveState;
    
        const url = manual_wallet
            ? `/api/v3/update/wallet/`
            : `/api/update/address/`;
    
        try {
            const response = await ApiService.post(
                `${url}${address_id}`,
                {
                    "_method": "PUT",
                    "active": newActiveState,
                },
                Cookies.get('token_auth')
            );
    
            if (response.status === 200) {
                refreshData();
                setState((prevState) => {
                    let updatedItems;
    
                    if (manual_wallet) {
                        updatedItems = prevState.walletOptions.wallets.map((wallet) =>
                            wallet.wallet_id === address_id
                                ? { ...wallet, active: newActiveState }
                                : wallet
                        );
    
                        return {
                            ...prevState,
                            walletOptions: {
                                ...prevState.walletOptions,
                                wallets: updatedItems,
                            },
                        };
                    } else {
                        updatedItems = prevState.walletOptions.addresses.map((address) =>
                            address.address_id === address_id
                                ? { ...address, active: newActiveState }
                                : address
                        );
    
                        return {
                            ...prevState,
                            walletOptions: {
                                ...prevState.walletOptions,
                                addresses: updatedItems,
                            },
                        };
                    }
                });
            } else {
                console.error("Erreur API :", response.error);
            }
        } catch (error) {
            console.error("Erreur lors de l'appel API :", error);
        }
    };
    
    const handleSelectAll = async () => {
        setState((prevState) => {
            const allWallets = [
                ...prevState.walletOptions.addresses.map(wallet => ({
                    key: wallet.address_id + "-" + wallet.created_at,
                    currentActive: wallet.active,
                    address_id: wallet.address_id
                })),
                ...prevState.walletOptions.wallets.map(wallet => ({
                    key: wallet.wallet_id + "-" + wallet.created_at,
                    currentActive: wallet.active,
                    wallet_id: wallet.wallet_id
                }))
            ];
    
            const isAllSelected = allWallets.every(wallet =>
                prevState.selectedWallets.includes(wallet.key)
            );
    
            // Basculer l'état actif de tous les portefeuilles
            allWallets.forEach(wallet => {
                handleToggleWallet(wallet.key, isAllSelected, wallet.address_id, false);
                handleToggleWallet(wallet.key, isAllSelected, wallet.wallet_id, true);
            });
    
            // Mise à jour de la sélection locale
            return {
                ...prevState,
                isManaging: true,
                selectedWallets: isAllSelected ? [] : allWallets.map(wallet => wallet.key),
            };
        });
    };
    
    const handleCopyAddress = (address) => {
        navigator.clipboard.writeText(address).then(() => {
            setState({
                ...state,
                openNotification: true,
                message: 'Adresse copiée dans le presse-papiers',
                notificationSeverity: 'success'
            });
        });
    };

    const toggleManageMode = () => {
        setState(prevState => ({
            ...prevState,
            isManaging: !prevState.isManaging
        }));
    };

    const startEditWallet = (wallet) => {
        setState(prevState => ({
            ...prevState,
            editingWallet: wallet
        }));
    };

    const handleAddWallet = () => {
        addWallet();
    };

    const refreshEditingWallet = async () => {
        try {
            const response = await ApiService.get(
                `/api/v3/get/user/wallet/${state.editingWallet.wallet_id}`,
                null,
                Cookies.get('token_auth')
            );
    
            const updatedWallet = response.data.wallets.find(w => w.wallet_id === state.editingWallet.wallet_id);
            const currency_symbol = response.data.currency_symbol || "";
    
            setState(prevState => ({
                ...prevState,
                editingWallet: {
                    ...updatedWallet,
                    currency_symbol: currency_symbol // Ajoute le symbole de la devise au portefeuille édité
                },
                walletOptions: {
                    ...prevState.walletOptions,
                    wallets: prevState.walletOptions.wallets.map(w =>
                        w.wallet_id === state.editingWallet.wallet_id 
                            ? { ...updatedWallet, currency_symbol } 
                            : w
                    ),
                    currency_symbol: currency_symbol // Mettez à jour également le symbole global dans walletOptions
                },
                loading: false
            }));
        } catch (error) {
            setState(prevState => ({
                ...prevState,
                notificationSeverity: 'error',
                message: error.error || "Erreur lors de la mise à jour",
                openNotification: true,
                loading: false
            }));
        }
    };    

    const { openNotification, notificationSeverity, message, selectedWallets, isManaging, editingWallet, loading } = state;

    return (
        <div>
            {/* Notification */}
            <Notification
                open={openNotification}
                handleClose={handleCloseNotification}
                severity={notificationSeverity}
                message={message}
            />

            <div className="wrapper-modal">
                <form className="head-modal">
                    {editingWallet ?
                        <EditWallet
                            walletId={editingWallet.wallet_id}
                            wallet={editingWallet}
                            onUpdate={handleUpdateWallet}
                            onClose={() => setState(prev => ({ ...prev, editingWallet: null }))}
                            refreshEditingWallet={refreshEditingWallet}
                        />
                        :
                        <div className="modal-my-wallet">
                            <div>
                                <p>Mes portefeuilles</p>
                                <button type="button" onClick={handleAddWallet}>
                                    <img src={IconPlus} alt="Icon Plus" />
                                </button>
                            </div>
                            <div>
                                <Separated type="line" customWidth="100%" />
                            </div>
                            {
                                loading ?
                                    <div className="modal-my-wallet-loading">
                                        <LoadingButton variant="primary"/>
                                    </div>
                                :
                                <>
                                {
                                    (state.walletOptions.addresses.length !== 0 || state.walletOptions.wallets.length !== 0) &&
                                    <div className="modal-my-wallet-gest">
                                        <span onClick={handleSelectAll}>Tout sélectionner</span>
                                        <button type="button" onClick={toggleManageMode}>
                                            {isManaging ? 'Annuler' : 'Gérer'}
                                        </button>
                                    </div>
                                }
                            <div className="modal-my-wallet-list">
                                <ul className="wallet-list">
                                    {
                                        state.walletOptions.addresses.length === 0 && state.walletOptions.wallets.length === 0 ?
                                            <div className="empty-wallet-list">
                                                {/* <img src={IconEmptyWallet} alt="Icon Empty Wallet" /> */}
                                                <p>Aucun portefeuille pour le moment...</p>
                                            </div>
                                        :
                                        <>
                                            {/* Boucle pour les adresses */}
                                            {state.walletOptions.addresses.map((wallet, index) => (
                                                <li key={index} className="wallet-item">
                                                    {isManaging && (
                                                        // <input
                                                        //     type="checkbox"
                                                        //     checked={selectedWallets.includes(wallet.address_id+"-"+wallet.created_at)}
                                                        //     onChange={() => handleToggleWallet(wallet.address_id+"-"+wallet.created_at)}
                                                        // />
                                                        <input
                                                            type="checkbox"
                                                            checked={wallet.active}
                                                            onChange={() => handleToggleWallet(wallet.address_id + "-" + wallet.created_at, wallet.active, wallet.address_id, false)}
                                                        />
                                                    )}
                                                    <div className="part1">
                                                        <img src={wallet.icon_max_crypto ? wallet.icon_max_crypto : IconInterogation} alt={`Img ${wallet.name_address}-${index}`} />
                                                    </div>
                                                    <div className="part2">
                                                        <div>
                                                            <TextShortener text={wallet.name_address} maxLength={15} />
                                                        </div>
                                                        <div>
                                                            <PriceFormat>{wallet.total_balance}</PriceFormat>{state.walletOptions.currency_symbol}
                                                        </div>
                                                    </div>
                                                    <div className="part3">
                                                        {isManaging ? (
                                                            <>
                                                                <button type="button" onClick={() => startEditWallet({ ...wallet, currency_symbol: state.walletOptions.currency_symbol })}>
                                                                    <img src={IconEdit} alt="Icon Edit" />
                                                                </button>
                                                                <button type="button" onClick={() => deleteAddress(wallet.address_id)}>
                                                                    <img src={IconTrash} alt="Icon Trash" />
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <button type="button" onClick={() => handleCopyAddress(wallet.address)}>
                                                                <img src={IconCopy} alt="Icon Copy" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </li>
                                            ))}
                                            
                                            {/* Boucle pour les portefeuilles */}
                                            {state.walletOptions.wallets.map((wallet, index) => (
                                                <li key={index} className="wallet-item">
                                                    {isManaging && (
                                                        // <input
                                                        //     type="checkbox"
                                                        //     checked={selectedWallets.includes(wallet.wallet_id+"-"+wallet.created_at)}
                                                        //     onChange={() => handleToggleWallet(wallet.wallet_id+"-"+wallet.created_at)}
                                                        // />
                                                        <input
                                                            type="checkbox"
                                                            checked={wallet.active}
                                                            onChange={() => handleToggleWallet(wallet.wallet_id + "-" + wallet.created_at, wallet.active, wallet.wallet_id, true)}
                                                        />
                                                    )}
                                                    <div className="part1">
                                                        <img src={wallet.wallet_logo} alt={`Img ${wallet.wallet_name}-${index}`} />
                                                    </div>
                                                    <div className="part2">
                                                        <div>
                                                            <TextShortener text={wallet.wallet_name} maxLength={20} />
                                                        </div>
                                                        <div>
                                                            <PriceFormat>{wallet.fiat_price}</PriceFormat>{state.walletOptions.currency_symbol}
                                                        </div>
                                                    </div>
                                                    <div className="part3">
                                                        {isManaging ? (
                                                            <>
                                                                <button type="button" onClick={() => startEditWallet({ ...wallet, currency_symbol: state.walletOptions.currency_symbol })}>
                                                                    <img src={IconEdit} alt="Icon Edit" />
                                                                </button>
                                                                <button type="button" onClick={() => deleteWallet(wallet.wallet_id)}>
                                                                    <img src={IconTrash} alt="Icon Trash" />
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <button type="button" onClick={() => handleCopyAddress(wallet.wallet_name)}>
                                                                <img src={IconCopy} alt="Icon Copy" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </li>
                                            ))}
                                        </>
                                    }

                                </ul>
                            </div>
                            </>
                            }
                        </div>
                    }
                </form>
                    {
                        // !editingWallet &&
                        // <div className="footer-modal">
                        //     <div>
                        //         <Button onClick={openAddCrypto}>Afficher</Button>
                        //     </div>
                        // </div>
                    }
            </div>
        </div>
    );
};

export default MyWallet;