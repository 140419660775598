import React from 'react';
import Cookies from 'js-cookie';

// CSS LINKS
import '../../../css/layouts/notification.css';
import '../../../css/layouts/modal.css';

// LAYOUTS COMPONENTS
import { Separated } from '../Index';

// ICON IMPORTS
import IconClose from "../../Icons/navbar/close-icon.svg";

// API COMPONENTS
import ApiService from "../../Server/ApiService";

class ShowNotification extends React.Component {
    handleRedirectUrl = (redirect_to_url) => {
        window.open(redirect_to_url, '_blank');
    }

    handleDeleteNotification = async (notification_id) => {
        try {
            const response = await ApiService.post(
              `/api/delete/notification/${notification_id}`,
              null,
              Cookies.get('token_auth'),
              null
            );
            
            if(response.status === 200) {
                this.props.refreshNotification();
            }else{
                console.error('Error fetching data:', response.error);
                this.props.refreshNotification();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    render() {
        if(this.props.type === "content"){
            return (
                <div className="wrapper-modal">
                    <div className="head-modal notification">
                        <p>Notifications</p>
                        <Separated customWidth="100%" type="line" />
                    </div>
                    <div className="middle-modal notification">
                        {this.props.notifications.map((item, index) => (
                            <div className="notification-item" key={index}>
                                <div className="notification-part-one">
                                    <div>
                                        <p>{item.title}</p>
                                    </div>
                                    <div>
                                        <span>{item.time}</span>
                                    </div>
                                </div>
                                <div className="notification-part-two">
                                    <img src={item.image_path} alt={`${index} Icon`} />
                                </div>
                                <div className="notification-close">
                                    <img
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.handleDeleteNotification(item.id);
                                        }}
                                        src={IconClose}
                                        alt={`Close Icon`}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }else{
            return (
                <div className="wrapper-modal">
                    <div className="head-modal notification">
                        <p>Notifications</p>
                        <Separated customWidth="100%" type="line" />
                    </div>
                    <div className="middle-modal notification">
                        {
                            this.props.notifications.length > 0 ?
                                this.props.notifications.sort((a, b) => b.id - a.id).map((item, index) => (
                                    <div onClick={() => this.handleRedirectUrl(item.redirect_to_url)} className="notification-item" key={index}>
                                        <div className="notification-part-one">
                                            <div>
                                                <p>{item.title}</p>
                                            </div>
                                            <div>
                                                <span>{item.time}</span>
                                            </div>
                                        </div>
                                        <div className="notification-part-two">
                                            <img src={item.image_path} alt={`${index} Icon`} />
                                        </div>
                                        <div className="notification-close">
                                            <img
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.handleDeleteNotification(item.id);
                                                }}
                                                src={IconClose}
                                                alt={`Close Icon`}
                                            />
                                        </div>
                                    </div>
                                ))
                            :
                            <div className="notification-item">
                                <p style={{textAlign: 'center'}}>Aucune notification pour le moment.</p>
                            </div>
                        }
                    </div>
                    <div className="footer-modal">
                        
                    </div>
                </div>
            );
        }
    }
}

export default ShowNotification;