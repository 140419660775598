import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ModalBottom = ({ type, items, isOpen, onClose, onItemClick }) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const modalBottomRef = useRef(null);

    const handleClickOutside = (event) => {
        if (modalBottomRef.current && !modalBottomRef.current.contains(event.target)) {
            initiateClose();
        }
    };

    const initiateClose = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setIsAnimating(false);
            onClose(); // Fermeture logique après l'animation
        }, 300); // Durée de l'animation de fermeture
    };

    useEffect(() => {
        if (isOpen) {
            setIsAnimating(false);
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            initiateClose();
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div className={`modal-bottom-overlay ${isOpen ? "show" : ""} ${isAnimating ? "close" : ""}`}>
            <div
                className={`modal-bottom-menu ${isOpen ? "show" : ""} ${isAnimating ? "close" : ""}`}
                ref={modalBottomRef}
            >
                {type === "list"
                    ? items.map((item, index) => (
                        item.to === null ? (
                            <button className="modal-bottom-link" onClick={item.onClick} key={index}>
                                <div className="modal-bottom-item">
                                    <div className="icon-container">
                                        {item.icon && <img src={item.icon} alt={`${item.label} Icon`} />}
                                    </div>
                                    <p>{item.label}</p>
                                </div>
                            </button>
                        ) : (
                            <Link to={item.to} className="modal-bottom-link" key={index}>
                                <div className="modal-bottom-item">
                                    <div className="icon-container">
                                        {item.icon && <img src={item.icon} alt={`${item.label} Icon`} />}
                                    </div>
                                    <p>{item.label}</p>
                                </div>
                            </Link>
                        )
                    ))
                    : items
                }
            </div>
        </div>
    );
};

export default ModalBottom;