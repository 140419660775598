import React from 'react';

// CSS LINKS
import '../../css/layouts/layouts.css';

// LIB
import SelectForm from "react-select";

// MUI COMPONENTS
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';

// ICONS IMPORTS
import LockIcon from '@mui/icons-material/Lock';
import IconButtonPhone from '../Icons/layouts/phone-icon.svg';

// Styled Components
const Root = styled('div')(({ theme }) => ({
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));

const Form = styled(Grid)(({ theme }) => ({
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));

const Size = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderRadius: "5px"
}));

const PaperStyled = styled('div')(({ theme }) => ({
    margin: theme.spacing(2, 6),
    flexDirection: "column",
    alignItems: "center",
    display: "flex"
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
    margin: theme.spacing(0),
    backgroundColor: theme.palette.primary.main
}));

const FormStyled = styled('form')(({ theme }) => ({
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
}));

const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "var(--primary-color)",
      backgroundColor: state.data.color,
      fontSize: state.selectProps.myFontSize
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize
    })
};

class Title extends React.Component {
    render() {
        return (
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                {this.props.children}
            </Typography>
        );
    }
}

class RootForm extends React.Component {
    render() {
        return (
            <Root>
                <Form container component="main">
                    {this.props.children}
                </Form>
            </Root>
        );
    }
}

class BoxForm extends React.Component {
    render() {
        return (
            <Size
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={1}
                square
            >
                <PaperStyled>
                    {this.props.children}
                </PaperStyled>
            </Size>
        );
    }
}

class ControlForm extends React.Component {
    render() {
        const { onSubmit } = this.props;
        return (
            <FormStyled onSubmit={onSubmit}>
                {this.props.children}
            </FormStyled>
        );
    }
}

class IconForm extends React.Component {
    render() {
        return (
            <AvatarStyled>
                <LockIcon />
            </AvatarStyled>
        );
    }
}

class Button extends React.Component {
    render() {
        const { type = null, link = null, loading = null, styleButton = null, disabled = null, onClick = null, minButton = null, rounded = null, variant = null, hover = null, iconRight = null, iconLeft = null, rotateInf = null} = this.props;

        if(type === "link"){
            return (
                <a
                    className={`button-global ${variant} ${hover === false && "no-hover"} ${minButton === true && "min-button"} ${rounded === true && "rounded"} ${rotateInf === true && "rotate-inf"}`}
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    style={styleButton}
                >
                    {loading ? (
                        <CircularProgress color="grey" />
                    ) : (
                        <>
                            {iconLeft && <img className="icon-left" src={iconLeft} alt={`Icon Left ${iconLeft}`} />}
                            {this.props.children}
                            {iconRight && <img className="icon-right" src={iconRight} alt={`Icon Right ${iconRight}`} />}
                        </>
                    )}
                </a>
            );
        }else{
            return (
                <button
                    className={`button-global ${variant} ${hover === false && "no-hover"} ${minButton === true && "min-button"} ${rounded === true && "rounded"} ${rotateInf === true && "rotate-inf"}`}
                    type={type || "button"}
                    style={styleButton}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {loading ? (
                        <CircularProgress color="grey" />
                    ) : (
                        <>
                            {iconLeft && <img className="icon-left" src={iconLeft} alt={`Icon Left ${iconLeft}`} />}
                            {this.props.children}
                            {iconRight && <img className="icon-right" src={iconRight} alt={`Icon Right ${iconRight}`} />}
                        </>
                    )}
                </button>
            );
        }
    }
}

class Input extends React.Component {
    render() {
        const { id, name, type, label, value, autoFocus, required, onChange, onBlur = null, autoComplete, classInput = null, styleCustom = null } = this.props;

        return (
            <input
                required={required || true}
                id={id}
                name={name}
                type={type}
                value={value}
                placeholder={label}
                autoFocus={autoFocus}
                onChange={onChange}
                onBlur={onBlur}
                autoComplete={autoComplete}
                className={`input-global ${classInput}`}
                style={styleCustom}
            />
        );
    }
}

class Select extends React.Component {
    render() {
        const { id, type = null, name, label, value, defaultValue = null, options, required, onChange, classSelect = null, styleCustom = null } = this.props;

        if(type !== "form"){
            return (
                <div className={`select-wrapper ${classSelect}`}>
                    <SelectForm
                        myFontSize="16px"
                        onChange={onChange}
                        classNamePrefix="select-global"
                        options={options}
                        placeholder={label}
                        defaultValue={defaultValue}
                    />
                </div>
            );
        }else{
            return (
                <div className={`select-wrapper ${classSelect}`}>
                    <label htmlFor={id}>{label}</label>
                    <select
                        required={required || true}
                        id={id}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`select-global`}
                        style={styleCustom}
                    >
                        <option value="" disabled>
                            Sélectionnez une option
                        </option>
                        {options.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            );
        }
    }
}

const CryptoSelect = ({ options, onChange, onScrollEnd, classSelect, defaultValue }) => {
    const selectRef = React.useRef(null);

    // Format des options pour inclure l'icône et le nom de la crypto
    const formattedOptions = options.map(option => ({
        value: option.name,
        label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={option.icon}
                    alt={option.name}
                    style={{ width: '20px', marginRight: '10px' }}
                />
                <span>{option.name} ({option.symbol})</span>
            </div>
        ),
    }));

    const handleMenuScroll = (event) => {
        const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
        if (bottom) {
            onScrollEnd();
        }
    };

    return (
        <div className={`select-wrapper ${classSelect}`}>
            <SelectForm
                ref={selectRef}
                onChange={onChange}
                options={formattedOptions}
                classNamePrefix="select-global"
                onMenuScrollToBottom={handleMenuScroll}
                placeholder="Rechercher une crypto"
                defaultValue={defaultValue} // Valeur par défaut ici
            />
        </div>
    );
};

class ButtonPhone extends React.Component {
    render() {
        return (
            <div className="container-button-phone">
                <a target="_blank" rel="noreferrer" href="https://calendly.com/cryptoinitiate-prise-rdv/30min?month=2024-08" className="button-phone">
                    <img src={IconButtonPhone} alt="Button Phone" />
                </a>
            </div>
        );
    }
}

class Copyright extends React.Component {
    render() {
        return (
            <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                <Link color="inherit" href="https://cryptoinitiate.fr">
                    Crypto Initiate
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
            </Typography>
        );
    }
}

class Separated extends React.Component {
    render() {
        const { customWidth = null, type = null } = this.props;

        if(type === "line"){
            if(customWidth){
                return (
                    <div className="separated-container line" style={{width: customWidth}}>
                        <div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                )
            }else{
                return (
                    <div className="separated-container">
                        <div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                );
            }
        }else{
            if(customWidth){
                return (
                    <div className="separated-container" style={{width: customWidth}}>
                        <div>
                            <div></div>
                            <div>
                                ou
                            </div>
                            <div></div>
                        </div>
                    </div>
                )
            }else{
                return (
                    <div className="separated-container">
                        <div>
                            <div></div>
                            <div>
                                ou
                            </div>
                            <div></div>
                        </div>
                    </div>
                );
            }
        }
    }
}

class Loading extends React.Component {
    render() {
        return (
            <div className="container-loading">
                <div className="spinner"></div>
            </div>
        );
    }
}

class LoadingButton extends React.Component {
    render() {
        const { variant = "white" } = this.props;

        return (
            <div className={`loader-button ${variant}`}></div>
        );
    }
}

export { RootForm, BoxForm, ControlForm, IconForm, Button, Input, Select, CryptoSelect, Title, ButtonPhone, Copyright, Separated, Loading, LoadingButton };