import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// CSS LINKS
import '../../../css/layouts/modal.css';
import '../../../css/layouts/custom-modal/add-crypto.css';

// LAYOUTS COMPONENTS
import { Input, CryptoSelect, Button, LoadingButton } from '../Index';

// API COMPONENTS
import ApiService from '../../Server/ApiService';

// Import Notification
import Notification from '../../Layouts/Notification';

const AddCrypto = () => {
    const navigate = useNavigate();
    
    const [page, setPage] = useState(1);
    const [options, setOptions] = useState([]);
    const [message, setMessage] = useState("");
    const [quantity, setQuantity] = useState("");
    const [loading, setLoading] = useState(false);
    const [walletName, setWalletName] = useState("");
    const [cryptoName, setCryptoName] = useState("");
    const [cryptoIcon, setCryptoIcon] = useState("");
    const [hasNextPage, setHasNextPage] = useState(true);
    const [purchasePrice, setPurchasePrice] = useState("");
    const [openNotification, setOpenNotification] = useState(false);
    const [notificationSeverity, setNotificationSeverity] = useState("");

    const fetchCryptoData = async (page) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://openapiv1.coinstats.app/coins?page=${page}&limit=50&currency=EUR`, {
                headers: {
                    'X-API-KEY': 'jSfQVvn1/uh5FKf36+D68JdOG/L2+HcK+I/f0V9vD20=',
                    'Accept': 'application/json',
                },
            });
    
            const data = response.data;
            if (data.result) {
                // Trier les cryptomonnaies par capitalisation boursière (marketCap)
                const sortedResults = data.result.sort((a, b) => b.marketCap - a.marketCap);
                setOptions((prevOptions) => [...prevOptions, ...sortedResults]);// Ajouter les nouvelles cryptos triées
                setHasNextPage(data.meta.hasNextPage);// Vérifier s'il y a d'autres pages
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des données:", error);
        } finally {
            setLoading(false);
        }
    };    

    // Charge la première page de données lors du montage
    useEffect(() => {
        fetchCryptoData(page);
    }, [page]);

    // Fonction pour charger la page suivante au scroll
    const handleScrollEnd = () => {
        if (hasNextPage && !loading) {
            setPage((prevPage) => prevPage + 1); // Charger la page suivante
        }
    };

    const handleQuantityChange = (e) => {
        let value = e.target.value;

        value = value.replace(',', '.');

        if (/^[0-9]*\.?[0-9]*$/.test(value)) {
            setQuantity(value);
        }
    };

    const handlePriceChange = (e) => {
        let value = e.target.value;

        value = value.replace(',', '.');

        if (/^[0-9]*\.?[0-9]*$/.test(value)) {
            setPurchasePrice(value);
        }
    };

    const addCrypto = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        try {
            const response = await ApiService.post(
                `/api/v3/create/wallet`,
                {
                    'quantity' : quantity,
                    'wallet_logo' : cryptoIcon,
                    'wallet_name' : walletName,
                    'wallet_crypto' : cryptoName,
                    'purchase_price' : purchasePrice
                },
                Cookies.get('token_auth'),
                null
            );
            
            setQuantity('');
            setCryptoName('');
            setCryptoIcon('');
            setWalletName('');
            setPurchasePrice('');

            setNotificationSeverity('success');
            setMessage(response.success);
            setOpenNotification(true);

            setTimeout(() => {
                navigate('/wallet');
            }, 3000);

        } catch (err) {
            setNotificationSeverity('error');
            setMessage(err.error);
            setOpenNotification(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseNotification = () => {
        setOpenNotification(false);
    };

    return (
        <div>
            <Notification
                open={openNotification}
                handleClose={handleCloseNotification}
                severity={notificationSeverity}
                message={message}
            />

            <form onSubmit={addCrypto} className="wrapper-modal">
                <div className="head-modal">
                    <p>Ajouter une crypto</p>
                </div>
                <div className="middle-modal add-crypto">
                    <div className="add-crypto-input">
                        <Input
                            id={"wallet-name"}
                            name={"walletName"}
                            classInput={"outlined"}
                            type={"text"}
                            label="Nom du portefeuille"
                            value={walletName}
                            onChange={(e) => setWalletName(e.target.value)}
                            styleCustom={{ width: "100%" }}
                        />
                    </div>
                    <div className="add-crypto-input">
                        <CryptoSelect
                            options={options}
                            classSelect="outlined"
                            onChange={(selectedOption) => {
                                setCryptoIcon(selectedOption.label.props.children[0].props.src);
                                setCryptoName(selectedOption.value);
                            }}
                            onScrollEnd={handleScrollEnd}
                        />
                    </div>
                    <div className="add-crypto-container-input">
                        <div className="add-crypto-input">
                            <Input
                                id={"quantity"}
                                name={"quantity"}
                                classInput={"outlined"}
                                type={"text"}
                                label="Quantité"
                                value={quantity}
                                onChange={handleQuantityChange}
                                styleCustom={{ width: "100%" }}
                            />
                        </div>
                        <div className="add-crypto-input">
                            <Input
                                id={"purchase-price"}
                                name={"purchasePrice"}
                                classInput={"outlined"}
                                type={"text"}
                                label="Prix d'achat"
                                value={purchasePrice}
                                onChange={handlePriceChange}
                                styleCustom={{ width: "100%" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="footer-modal add-crypto">
                    <Button type="submit">
                        {loading ? <LoadingButton /> : "Ajouter"}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AddCrypto;