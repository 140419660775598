import React, { useState } from 'react';

// CSS LINKS
import '../../../css/layouts/modal.css';
import '../../../css/layouts/custom-modal/edit-wallet.css';

// ICON IMPORTS
import IconEdit from "../../Icons/modal/edit-icon.svg";
import IconTrash from "../../Icons/modal/trash-icon.svg";
import IconArrowLeft from "../../Icons/modal/arrow-left-icon.svg";

// LAYOUTS COMPONENTS
import EditCrypto from './EditCrypto';
import PriceFormat from '../PriceFormat';
import { Input, Button } from '../Index';
import Notification from '../Notification';
import TextShortener from '../TextShortener';

const EditWallet = ({ wallet, onUpdate, onClose, refreshEditingWallet }) => {
    const [addressName, setAddressName] = useState(wallet.wallet_id ? wallet.wallet_name : wallet.name_address || '');
    const [openNotification, setOpenNotification] = useState(false);
    const [notificationSeverity, setNotificationSeverity] = useState('');
    const [message, setMessage] = useState('');
    const [editingCrypto, setEditingCrypto] = useState(null);
    const [notification, setNotification] = useState({ open: false, severity: '', message: '' });

    const handleSave = () => {
        const updatedWallet = { ...wallet, addressName };
        const walletOrManualWallet = wallet.wallet_id ? 0 : 1;
        
        onUpdate(updatedWallet, walletOrManualWallet);
    };

    const handleNotification = (severity, message) => {
        setNotification({ open: true, severity, message });
    };

    const handleCloseNotification = () => {
        setOpenNotification(false);
        setNotification({ ...notification, open: false });
    };

    const startEditWallet = (crypto) => {
        setEditingCrypto(crypto);
    };

    const onGetWalletUpdated = () => {
        refreshEditingWallet();
    }

    return (
        <>
            <div style={{position: 'absolute', width: "100%", height: "100vh"}}>
                <Notification
                    open={openNotification}
                    handleClose={handleCloseNotification}
                    severity={notificationSeverity}
                    message={message}
                />
                <Notification
                    open={notification.open}
                    handleClose={handleCloseNotification}
                    severity={notification.severity}
                    message={notification.message}
                />
            </div>
            {editingCrypto ? (
                <EditCrypto 
                    walletId={wallet.wallet_id}
                    crypto={editingCrypto}
                    onNotify={handleNotification}
                    onClose={() => setEditingCrypto(null)}
                    onGetWalletUpdated={onGetWalletUpdated}
                />
            ) : (
                <div className="modal-edit-wallet">
                    <div>
                        <button type="button" onClick={onClose}>
                            <img src={IconArrowLeft} alt="Icon Arrow Left" />
                        </button>
                        <p>Modifier le portefeuille</p>
                    </div>
                    <div>
                        <Input
                            type="text"
                            classInput="outlined"
                            value={addressName}
                            onChange={(e) => setAddressName(e.target.value)}
                            placeholder="Nom du portefeuille"
                        />
                    </div>
                    <div className="modal-edit-wallet-list">
                        <ul className="crypto-list">
                            {/* {(wallet.cryptos || []).map((crypto, index) => (
                                <li key={index} className="crypto-item">
                                    <div className="part1">
                                        <img src={crypto.wallet_logo} alt={`Img ${crypto.wallet_crypto}-${index}`} />
                                    </div>
                                    <div className="part2">
                                        <div>
                                            <TextShortener text={crypto.wallet_crypto} maxLength={20} />
                                        </div>
                                        <div>
                                            <PriceFormat>{crypto.purchase_price}</PriceFormat>{crypto.currencySymbol}
                                        </div>
                                    </div>
                                    <div className="part3">
                                        <button type="button" onClick={() => startEditWallet(crypto)}>
                                            <img src={IconEdit} alt="Icon Edit" />
                                        </button>
                                        <button type="button">
                                            <img src={IconTrash} alt="Icon Trash" />
                                        </button>
                                    </div>
                                </li>
                            ))} */}
                            {
                                wallet.wallet_id ? (
                                    <li key={wallet.wallet_id} className="crypto-item">
                                        <div className="part1">
                                            <img src={wallet.wallet_logo} alt={`Img ${wallet.wallet_crypto}-${wallet.wallet_id}`} />
                                        </div>
                                        <div className="part2">
                                            <div>
                                                <TextShortener text={wallet.wallet_crypto} maxLength={20} />
                                            </div>
                                            <div>
                                                <PriceFormat>{wallet.fiat_price}</PriceFormat>{wallet.currency_symbol}
                                            </div>
                                        </div>
                                        <div className="part3">
                                            <button type="button" onClick={() => startEditWallet(wallet)}>
                                                <img src={IconEdit} alt="Icon Edit" />
                                            </button>
                                            <button type="button">
                                                <img src={IconTrash} alt="Icon Trash" />
                                            </button>
                                        </div>
                                    </li>
                                ) : (
                                    Array.isArray(wallet.cryptos) && wallet.cryptos.map((crypto) => (
                                        <li key={crypto.id} className="crypto-item">
                                            <div className="part1">
                                                <img src={crypto.iconUrl} alt={`Image ${crypto.symbol}`} />
                                            </div>
                                            <div className="part2">
                                                <div>
                                                    <TextShortener text={crypto.symbol} maxLength={20} />
                                                </div>
                                                <div>
                                                    <PriceFormat>{crypto.total}</PriceFormat>{wallet.currency_symbol}
                                                </div>
                                            </div>
                                            <div className="part3">
                                                {/* <button type="button" onClick={() => startEditWallet(crypto)}>
                                                    <img src={IconEdit} alt="Icon Edit" />
                                                </button>
                                                <button type="button" onClick={() => handleDeleteCrypto(crypto.id)}>
                                                    <img src={IconTrash} alt="Icon Trash" />
                                                </button> */}
                                            </div>
                                        </li>
                                    ))
                                    ||
                                    <li className="crypto-item">Un problème est survenu pendant le chargement des cryptos...</li>
                                )
                            }
                        </ul>
                    </div>
                    <div style={{marginTop: "10px"}}>
                        <Button type="button" onClick={handleSave}>Modifier</Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default EditWallet;