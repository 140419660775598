import Cookies from 'js-cookie'
import ApiService from './ApiService';

const SubscribeService = {
  isSubscribe: false,

  isSubscribedUser: async () => {
    try {
      const url = '/api/get/user/subscriptions';
      const response = await ApiService.get(url, null, Cookies.get("token_auth"));

      if (response.success === true) {
        if(response.has_subscription){
          SubscribeService.isSubscribe = true;
        }else{
          SubscribeService.isSubscribe = false;
        }
        
        return {
          'subscribe': true,
        };
      }else{
        SubscribeService.isSubscribe = false;

        return {
          'subscribe': false,
        };
      }
    } catch (error) {
      console.error('Erreur lors de la vérification de l\'abonnement :', error);
      SubscribeService.isSubscribe = false;
      return false;
    }
  },
};

export default SubscribeService;