import IconInvest from "../../Icons/sidebar/invest-icon.svg";
import IconWallet from "../../Icons/sidebar/wallet-icon.svg";
import IconAcademy from "../../Icons/sidebar/academy-icon.svg";
import IconMarkets from "../../Icons/sidebar/markets-icon.svg";
import IconAccompaniment from "../../Icons/sidebar/accompaniment-icon.svg";

export const sharedList = [
    { icon: IconWallet, label: "Portefeuille", to: "/wallet" },
    { icon: IconAccompaniment, label: "Accompagnement", to: "/accompaniment" },
    { icon: IconInvest, label: "Investir", to: "/invest" },
    { icon: IconMarkets, label: "Marchés", to: "/" },
    { icon: IconAcademy, label: "Académie", to: "/academy" }
];