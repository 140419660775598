import React from 'react';
import { useNavigate } from 'react-router-dom';

// CSS LINKS
import '../../../css/layouts/setting.css';
import '../../../css/layouts/modal.css';

const SettingAccount = (props) => {
    const navigate = useNavigate();

    const redirect = (to) => {
        navigate(to);
    };

    return (
        <div className="wrapper-modal setting">
            <div className="middle-modal">
                {props.listSetting.map((item, index) => (
                    item.type === "link" ?
                    <a
                        className="modal-bottom-link"
                        href={item.to}
                        target="_blank"
                        rel="noreferrer"
                        key={index}
                    >
                        <div className="modal-bottom-item">
                            <div className="icon-container">
                                {item.icon && <img src={item.icon} alt={`${item.label} Icon`} />}
                            </div>
                            <p>{item.label}</p>
                        </div>
                    </a>
                    :
                    <button
                        className="modal-bottom-link"
                        onClick={() => redirect(item.to)} // Utilisation de la fonction anonyme
                        key={index}
                    >
                        <div className="modal-bottom-item">
                            <div className="icon-container">
                                {item.icon && <img src={item.icon} alt={`${item.label} Icon`} />}
                            </div>
                            <p>{item.label}</p>
                        </div>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default SettingAccount;