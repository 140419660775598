import React from 'react';

//CSS LINK
import '../../../css/layouts/board/board.css';

// LAYOUTS COMPONENTS
import Navbar from '../../Layouts/Board/Navbar';
import SideBar from '../../Layouts/Board/SideBar';
import { ButtonPhone } from '../../Layouts/Index';

class PageBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          addWalletFunction: null,  // Ici nous allons stocker la fonction handleAddWallet
        };
    }

    receiveAddWalletFunction = (addWalletFn) => {
        this.setState({ addWalletFunction: addWalletFn });
    
        if (this.props.sendAddCryptoFunction) {
            this.props.sendAddCryptoFunction(addWalletFn);
        }
    }
    refreshData = () => {
        this.props.refreshData();
    }

    syncData = () => {
        this.props.syncData();
    }
    
    render() {
        return (
            <div className="container-board">
                <SideBar />
                <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
                    <Navbar buttonAddWallet={this.props.buttonAddWallet} buttonMyWallet={this.props.buttonMyWallet} iconRefresh={this.props.iconRefresh} refreshData={this.refreshData} syncData={this.syncData} buttonSync={this.props.buttonSync} sendAddCryptoFunction={this.receiveAddWalletFunction}  />
                    <div className="container-board-children">
                        {this.props.children}
                    </div>
                </div>
                <ButtonPhone />
            </div>
        );
    }
}

export default PageBoard;