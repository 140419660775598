import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

// CSS LINKS
import '../../../css/layouts/modal.css';
import '../../../css/layouts/custom-modal/edit-wallet.css';

// ICON IMPORTS
import IconArrowLeft from "../../Icons/modal/arrow-left-icon.svg";

// LAYOUTS COMPONENTS
import { Input, Button, CryptoSelect } from '../Index';
import Notification from '../Notification';

// API COMPONENTS
import ApiService from "../../Server/ApiService";

function assembleString(arr) {
    return arr.join('');
}

const EditCrypto = ({ walletId, crypto, onClose, onNotify, onGetWalletUpdated }) => {
    const [quantity, setQuantity] = useState("");
    const [cryptoName, setCryptoName] = useState("");
    const [cryptoIcon, setCryptoIcon] = useState("");
    const [purchasePrice, setPurchasePrice] = useState("");
    const [rawPurchasePrice, setRawPurchasePrice] = useState("");
    const [options, setOptions] = useState([]);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [loading, setLoading] = useState(false);
    const [openNotification, setOpenNotification] = useState(false);
    const [notificationSeverity, setNotificationSeverity] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchCryptoData(page);
        setQuantity(crypto.quantity);
        setRawPurchasePrice(crypto.purchase_price);
        setPurchasePrice(crypto.purchase_price);
        setCryptoIcon(crypto.wallet_logo);
        setCryptoName(crypto.wallet_crypto);
    }, [page]);

    const fetchCryptoData = async (page) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://openapiv1.coinstats.app/coins?page=${page}&limit=50&currency=EUR`, {
                headers: {
                    'X-API-KEY': 'jSfQVvn1/uh5FKf36+D68JdOG/L2+HcK+I/f0V9vD20=',
                    'Accept': 'application/json',
                },
            });

            const data = response.data;
            if (data.result) {
                const sortedResults = data.result.sort((a, b) => b.marketCap - a.marketCap);
                setOptions((prevOptions) => [...prevOptions, ...sortedResults]);
                setHasNextPage(data.meta.hasNextPage);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleScrollEnd = () => {
        if (hasNextPage && !loading) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleSave = async () => {
        // Créer updatedWallet avec seulement les champs modifiés
        const updatedWallet = {};
        if (quantity !== crypto.quantity) updatedWallet.quantity = quantity;
        if (cryptoName !== crypto.wallet_crypto) updatedWallet.wallet_crypto = cryptoName;
        if (cryptoIcon !== crypto.wallet_logo) updatedWallet.wallet_logo = cryptoIcon;
        if (rawPurchasePrice !== crypto.purchase_price) updatedWallet.purchase_price = rawPurchasePrice;
        console.log(cryptoIcon);
        // Vérifier si des modifications ont été faites avant d'envoyer la requête
        if (Object.keys(updatedWallet).length === 0) {
            onNotify('info', 'Aucune modification détectée.');
            return;
        }

        updatedWallet._method = "PUT";

        try {
            const response = await ApiService.post(
                `/api/v3/update/wallet/crypto/${walletId}`,
                updatedWallet,
                Cookies.get('token_auth'),
                null
            );
    
            if (response.status === 200) {
                onNotify('success', 'Modifications enregistrées avec succès.');
                onGetWalletUpdated();
                onClose();
            }
        } catch (err) {
            onNotify('error', 'Une erreur s\'est produite.');
        }
    };

    const handleCloseNotification = () => {
        setOpenNotification(false);
    };

    const handleQuantityChange = (e) => {
        let value = e.target.value;

        value = value.replace(',', '.');

        if (/^[0-9]*\.?[0-9]*$/.test(value)) {
            setQuantity(value);
        }
    };

    const handlePriceChange = (e) => {
        let value = e.target.value;

        value = value.replace(',', '.');

        if (/^[0-9]*\.?[0-9]*$/.test(value)) {
            setRawPurchasePrice(value); // mettre à jour la valeur brute
            // setPurchasePrice(PriceFormat(value)); // mettre à jour la valeur formatée pour l'affichage
            setPurchasePrice(value);
        }
    };

    const PriceFormat = (value) => {
        const numberValue = Number(value);
        if (isNaN(numberValue)) {
            return ""; // ou toute valeur par défaut
        }
        return numberValue.toLocaleString('fr-FR');
    };

    return (
        <>
            {/* Notification */}
            <Notification
                open={openNotification}
                handleClose={handleCloseNotification}
                severity={notificationSeverity}
                message={message}
            />
            <div className="modal-edit-wallet">
                <div>
                    <button type="button" onClick={onClose}>
                        <img src={IconArrowLeft} alt="Icon Arrow Left" />
                    </button>
                    <p>Modifier une crypto</p>
                </div>
                <div style={{ marginBottom: "15px" }}>
                    <CryptoSelect
                        options={options}
                        classSelect="outlined"
                        onChange={(selectedOption) => {
                            setCryptoIcon(selectedOption.label.props.children[0].props.src);
                            setCryptoName(assembleString(selectedOption.label.props.children[1].props.children));
                        }}
                        onScrollEnd={handleScrollEnd}
                        defaultValue={{
                            value: crypto.name,
                            label: (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={crypto.wallet_logo} alt={crypto.currency_symbol} style={{ width: '20px', marginRight: '10px' }} />
                                    <span>{crypto.wallet_crypto}</span>
                                </div>
                            ),
                        }}
                    />
                </div>
                <div className="add-crypto-container-input">
                    <div className="add-crypto-input">
                        <Input
                            id={"quantity"}
                            name={"quantity"}
                            classInput={"outlined"}
                            type={"text"}
                            label="Quantité"
                            value={quantity}
                            onChange={handleQuantityChange}
                            styleCustom={{ width: "100%" }}
                        />
                    </div>
                    <div className="add-crypto-input">
                        <Input
                            id={"purchase-price"}
                            name={"purchasePrice"}
                            classInput={"outlined"}
                            type={"text"}
                            label="Prix d'achat"
                            value={purchasePrice}
                            onChange={handlePriceChange}
                            styleCustom={{ width: "100%" }}
                        />
                        <span style={{
                            width: "10px",
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            pointerEvents: 'none',
                            color: '#888'
                        }}>
                            {crypto.currency_symbol}
                        </span>
                    </div>
                </div>
                <div>
                    <Button type="button" onClick={handleSave}>Modifier</Button>
                </div>
            </div>
        </>
    );
};

export default EditCrypto;