import React from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom'; // Import du hook pour la navigation

// CSS LINKS
import '../../../css/layouts/modal.css';

// LAYOUTS COMPONENTS
import { Input, Button, Separated, LoadingButton } from '../Index';

// ICON IMPORTS
import IconPlus from "../../Icons/modal/plus-icon.svg";

// API COMPONENTS
import ApiService from "../../Server/ApiService";

// Import Notification
import Notification from '../../Layouts/Notification';

const AddWallet = ({ autoFocus, openAddCrypto, refreshData }) => {
    const navigate = useNavigate(); // Hook pour la navigation

    const [state, setState] = React.useState({
        address: "",
        message: "",
        loading: false,
        openNotification: false, // Pour ouvrir/fermer la notification
        notificationSeverity: "", // Pour le type de notification (success ou error)
    });

    const handleAddAddress = (e) => {
        setState({ ...state, address: e.target.value });
    };

    const newAddress = async (e) => {
        e.preventDefault();  // Empêche le comportement par défaut du formulaire
        setState({ ...state, loading: true });
        try {
            const response = await ApiService.post(
                `/api/v4/create/address`,
                { network: 'ethereum', address: state.address },
                Cookies.get('token_auth'),
                null
            );

            if(response.status === 200) {
                
                // Succès de la requête
                setState({
                    ...state,
                    notificationSeverity: 'success',
                    message: response.success,
                    loading: false,
                    openNotification: true
                });

                refreshData();

                setTimeout(() => {
                    navigate('/wallet');
                }, 3000);
            }else{
                refreshData();
                // Erreur dans la requête
                setState({
                    ...state,
                    notificationSeverity: 'error',
                    message: response.error,
                    loading: false,
                    openNotification: true // Ouvre la notification
                });
            }

        } catch (err) {
            refreshData();
            // Erreur dans la requête
            setState({
                ...state,
                notificationSeverity: 'error',
                message: err.error,
                loading: false,
                openNotification: true // Ouvre la notification
            });
        }
    };

    const handleCloseNotification = () => {
        setState({ ...state, openNotification: false });
    };

    const { openNotification, notificationSeverity, message } = state;

    return (
        <div>
            {/* Notification */}
            <Notification
                open={openNotification}
                handleClose={handleCloseNotification}
                severity={notificationSeverity}
                message={message}
            />

            <div className="wrapper-modal">
                <form onSubmit={newAddress} className="head-modal">
                    <p>Ajouter un portefeuille</p>
                    <div>
                        <Input
                            id={"add-address-wallet"}
                            name={"add-address-wallet"}
                            type={"text"}
                            label="Ajouter une adresse"
                            autoFocus={autoFocus}
                            styleCustom={{ width: "100%", paddingRight: "50px" }}
                            onChange={handleAddAddress}
                        />
                        <button type="submit">
                            {state.loading ? <LoadingButton /> : <img src={IconPlus} alt="Icon Plus" />}
                        </button>
                    </div>
                </form>
                <div className="middle-modal">
                    <Separated />
                </div>
                <div className="footer-modal">
                    <div>
                        <Button onClick={openAddCrypto}>Ajouter manuellement des cryptos</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddWallet;