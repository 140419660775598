import React from 'react';

const TextShortener = ({ text, maxLength }) => {
  const shortenText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }

    const startLength = Math.floor(maxLength / 2);
    const endLength = maxLength - startLength - 3;
    
    const start = text.slice(0, startLength);
    const end = text.slice(-endLength);

    return `${start}...${end}`;
  };

  return <p>{shortenText(text, maxLength)}</p>;
};

export default TextShortener;