import { Link } from "react-router-dom";
import React, { Component } from 'react';
import { sharedList } from './sharedList';

// CSS LINKS
import '../../../css/layouts/board/sidebar.css';

// ICON IMPORTS
import IconCryptoInitiateLogo from "../../Icons/sidebar/cryptoinitiate-logo.svg";
import IconCryptoInitiateLogoMin from "../../Icons/sidebar/cryptoinitiate-logo-min.svg";
import IconChevronLeft from "../../Icons/sidebar/chevron-left-icon.svg";

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,  // État pour l'ouverture/fermeture de la sidebar
            hoveredItem: null,  // État pour gérer l'icône survolée
            isMobile: window.innerWidth <= 768,  // État pour savoir si c'est mobile
        };
    }

    // Méthode pour écouter la taille de l'écran
    handleResize = () => {
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);  // Écoute les changements de taille de l'écran
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize); // Nettoie l'écouteur d'événements
    }

    toggleSidebar = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    handleMouseEnter = (index) => {
        this.setState({ hoveredItem: index });
    }

    handleMouseLeave = () => {
        this.setState({ hoveredItem: null });
    }

    redirectHome = () => {
        window.location.href = "https://cryptoinitiate.fr";
    }

    render() {
        const { isOpen, hoveredItem, isMobile } = this.state;
        const sidebarClass = isOpen ? "container-sidebar active" : "container-sidebar";

        return (
            <>
                <div className={sidebarClass}>
                    <div className="wrapper-sidebar">
                        <div onClick={this.redirectHome} className="logo-sidebar-full">
                            <img src={IconCryptoInitiateLogo} alt="CryptoInitiate Logo" />
                        </div>
                        <div onClick={this.redirectHome} className="logo-sidebar-min">
                            <img src={IconCryptoInitiateLogoMin} alt="CryptoInitiate Logo Min" />
                        </div>
                        <div className="list-sidebar">
                            {sharedList.map((item, index) => (
                                <Link
                                    to={item.to}
                                    key={index}
                                    onMouseEnter={() => this.handleMouseEnter(index)}
                                    onMouseLeave={this.handleMouseLeave}
                                >
                                    <div className="sidebar-item">
                                        <div className="icon-container">
                                            <img src={item.icon} alt={`${index} Icon`} />
                                        </div>
                                        <p className={hoveredItem === index ? "show" : ""}>
                                            {item.label}
                                        </p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        {/* Bouton pour ouvrir/fermer la sidebar en version desktop */}
                        <div className={`toggle-sidebar ${isOpen && "opened"}`} onClick={this.toggleSidebar}>
                            <img
                                src={IconChevronLeft}
                                alt="Chevron Left Icon"
                                className={`${isOpen ? "" : "rotate"}`}
                            />
                        </div>
                    </div>
                </div>
                {isMobile && (
                    isOpen && <div className="overlay-sidebar" onClick={this.toggleSidebar}></div>
                )}
            </>
        );
    }
}

export default SideBar;