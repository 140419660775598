import React, { Component, createRef } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { sharedList } from './sharedList';

// CSS LINKS
import '../../../css/layouts/board/navbar.css';

// LAYOUTS COMPONENTS
import AddWallet from '../../Layouts/Modal/AddWallet';
import ModalBottom from '../../Layouts/Modal/ModalBottom';
import SettingAccount from '../../Layouts/Modal/SettingAccount';
import ShowNotification from '../../Layouts/Modal/ShowNotification';

// ICON IMPORTS
import IconUser from "../../Icons/navbar/user-icon.svg";
import IconFolder from "../../Icons/navbar/foler-icon.svg";
import IconLogout from "../../Icons/navbar/logout-icon.svg";
import IconSetting from "../../Icons/navbar/setting-icon.svg";
import IconRefresh from "../../Icons/navbar/refresh-icon.svg";
import IconChevronDown from "../../Icons/navbar/chevron-down-icon.svg";
import IconMenuHamburger from "../../Icons/sidebar/hamburger-icon.svg";
import IconNotification from "../../Icons/navbar/notification-icon.svg";
import IconCryptoInitiateLogoMin from "../../Icons/sidebar/cryptoinitiate-logo-min.svg";
import MyWallet from '../Modal/MyWallet';
import AddCrypto from '../Modal/AddCrypto';

// API COMPONENTS
import ApiService from "../../Server/ApiService";
import { Button } from '../Index';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listNavbar: [],
            notifications: [],
            modalMyWallet: false,
            modalAddWallet: false,
            modalAddCrypto: false,
            isOpenMyWallet: false,
            isOpenAddWallet: false,
            isOpenAddCrypto: false,
            modalNotification: false,
            modalNavbarAccount: false,
            isOpenNotification: false,
            isOpenNavbarAccount: false,
            loadingNotification: false,
            isOpenNavbarNotification: false,
            isMobile: window.innerWidth <= 768,
        };
        this.modalRef = createRef();
    }

    handleResize = () => {
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    handleAddWallet = () => {
        if(this.state.isMobile){
            this.setState({ isOpenAddWallet: true, isOpenMyWallet: false });
        }else{
            this.setState({ modalAddWallet: true, modalMyWallet: false });
        }
    }

    handleMyWallet = () => {
        if (!this.state.modalMyWallet) {
            // if (this.state.isMobile) {
            //     this.setState({ isOpenNotification: true, isOpenNavbar: false });
            // } else {
            //     this.setState({ modalNotification: true, isOpenNavbar: false });
            // }
            if(this.state.isMobile){
                this.setState({ isOpenMyWallet: true });
            }else{
                this.setState({ modalMyWallet: true });
            }
        } else {
            if(this.state.isMobile){
                this.setState({ isOpenMyWallet: false });
            }else{
                this.setState({ modalMyWallet: false });
            }
        }
    }

    handleNotification = () => {
        if (!this.state.modalNotification) {
            if (this.state.isMobile) {
                this.setState({ isOpenNotification: true, isOpenNavbar: false });
            } else {
                this.setState({ modalNotification: true, isOpenNavbar: false });
            }
        } else {
            if (this.state.isMobile) {
                this.setState({ isOpenNotification: false });
            } else {
                this.setState({ modalNotification: false });
            }
        }
    }

    handleNavbarNotification = () => {
        if (this.state.isMobile) {
            this.setState({ isOpenNavbar: false, isOpenNavbarNotification: true });
        } else {
            this.setState({ modalNotification: false });
        }
    }

    handleNavbarAccount = () => {
        if (this.state.isMobile) {
            this.setState({ isOpenNavbar: false, isOpenNavbarAccount: true });
        } else {
            this.setState({ modalNavbarAccount: true });
        }
    }
    
    handleClickOutside = (event) => {
        if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
            this.setState({ modalAddWallet: false, modalMyWallet: false, modalAddCrypto: false, isOpenAddWallet: false, isOpenNavbar: false, isOpenNotification: false, modalNavbarAccount: false, modalNotification: false });
        }
    }

    getNotifications = async () => {
        this.setState(prevState => ({ ...prevState, loading: true }));
        
        try {
            const response = await ApiService.get(
                `/api/get/notifications?user_id=1`,
                null,
                Cookies.get('token_auth'),
                null
            );
    
            if(response.status === 200) {
                const notifications = response.data || [];
            
                this.setState(prevState => ({
                    ...prevState,
                    notifications: notifications,
                    loadingNotification: false
                }));
            }else{
                this.setState(prevState => ({
                    ...prevState,
                    notificationSeverity: 'error',
                    message: response.error,
                    openNotification: true,
                    loadingNotification: false
                }));
            }
        } catch (err) {
            this.setState(prevState => ({
                ...prevState,
                notificationSeverity: 'error',
                message: err.error,
                openNotification: true,
                loadingNotification: false
            }));
        }
    };

    componentDidMount() {
        if (this.props.sendAddCryptoFunction) {
            this.props.sendAddCryptoFunction(this.handleAddCrypto);
        }

        this.getNotifications();

        let list = sharedList;
        list = [...list, { icon: IconNotification, label: "Notifications", to: "/" }, { icon: IconUser, label: "Compte", to: "/" }];

        this.setState({ listNavbar: list });

        document.addEventListener('touchstart', this.handleClickOutside);
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        document.removeEventListener('touchstart', this.handleClickOutside);
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.handleResize);
    }
    
    toggleMenu = () => {
        this.setState(prevState => ({
            isOpenNavbar: !prevState.isOpenNavbar
        }));
    }

    handleMenuItemClick = (item) => {
        return item;
    };

    handleCloseModalBottom = () => {
        this.setState({ isOpenNavbar: false, isOpenAddWallet: false});
    }
    
    handleCloseModalBottomTwo = () => {
        this.setState({ isOpenNavbar: false, isOpenNavbarAccount: false, isOpenNavbarNotification: false, isOpenAddWallet: false});
    }

    handleCloseModalBottomTree = () => {
        this.setState({ isOpenNavbar: false, isOpenNavbarAccount: false, isOpenNavbarNotification: false, isOpenAddWallet: false});
    }

    handleAddCrypto = () => {
        if(this.state.isMobile){
            this.setState({ isOpenAddCrypto: true, isOpenAddWallet: false });
        }else{
            this.setState({ modalAddCrypto: true, modalMyWallet: false });
        }
    };

    refreshData = () => {
        this.props.refreshData();
    }

    refreshNotification = () => {
        this.getNotifications();
    }

    syncData = () => {
        this.props.syncData();
        this.props.refreshData();
    }

    render() {
        const dropdownItems = [
            ...sharedList,
            { icon: IconNotification, label: "Notifications", to: null, onClick: this.handleNavbarNotification },
            { icon: IconUser, label: "Compte", to: null, onClick: this.handleNavbarAccount }
        ];

        const dropdownItemsAccount = [
            { type: "page", icon: IconSetting, label: "Paramètres", to: "/settings" },
            { type: "link", icon: IconFolder, label: "Ressources", to: "http://cryptoinitiate.notion.site" },
            { type: "page", icon: IconLogout, label: "Se déconnecter", to: "/logout" }
        ];

        // const notificationItems = [
        //     { icon: "https://api.cryptoinitiate.fr/storage/images/BgwbrOlR66Mz5F55s7WnXaymZ0mlx8QVfvmrlVeq.png", text: "Premier cours de Trading", time: "Il y a 7 heures" },
        //     { icon: "https://api.cryptoinitiate.fr/storage/images/BgwbrOlR66Mz5F55s7WnXaymZ0mlx8QVfvmrlVeq.png", text: "Deuxième cours de Cryptomonaie", time: "Il y a 13 jours" },
        //     { icon: "https://api.cryptoinitiate.fr/storage/images/BgwbrOlR66Mz5F55s7WnXaymZ0mlx8QVfvmrlVeq.png", text: "Premier cours de Cryptomonaie", time: "Il y a 18 jours" },
        //     { icon: "https://api.cryptoinitiate.fr/storage/images/BgwbrOlR66Mz5F55s7WnXaymZ0mlx8QVfvmrlVeq.png", text: "Premier cours de Cryptomonaie", time: "Il y a 18 jours" }
        // ];

        return (
            <>
                <div className="container-navbar">
                    <div className="wrapper-navbar">
                        <div className="logo-navbar-min">
                            <img src={IconCryptoInitiateLogoMin} alt="CryptoInitiate Logo Min" />
                        </div>
                        <div className="interaction-navbar">
                            {this.props.buttonAddWallet &&
                                (
                                    this.state.isMobile ?
                                        <>
                                            <div onClick={this.handleAddWallet}>
                                                <div className="button-navbar-outlined clickable">
                                                    Ajouter un portefeuille
                                                </div>
                                            </div>
                                        </>
                                    :
                                    <>
                                        <div onClick={this.handleAddWallet}>
                                            <div className="button-navbar-outlined clickable">
                                                Ajouter un portefeuille
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                this.state.modalAddWallet && (
                                    <div
                                        className={`container-modal show`}
                                        ref={this.modalRef}
                                        // onClick={this.handleOverlayClick}
                                    >
                                        <AddWallet autoFocus={true} openAddCrypto={this.handleAddCrypto} refreshData={this.refreshData} />
                                    </div>
                                )
                            }
                            {
                                this.state.modalAddCrypto && (
                                    <div
                                        className={`container-modal show`}
                                        ref={this.modalRef}
                                        // onClick={this.handleOverlayClick}
                                    >
                                        <AddCrypto autoFocus={true} />
                                    </div>
                                )
                            }
                            {this.props.buttonMyWallet &&
                                (
                                    this.state.isMobile ?
                                        <>
                                            <div onClick={this.handleMyWallet} className={this.props.buttonMyWallet ? "my-wallet outlined link" : "my-wallet outlined link hidden"}>
                                                <div>Mes portefeuilles</div>
                                                <img src={IconChevronDown} alt="Chevron Down Icon" />
                                            </div>
                                        </>
                                    :
                                        <>
                                            <div onClick={this.handleMyWallet} className={this.props.buttonMyWallet ? "my-wallet outlined link" : "my-wallet outlined link hidden"}>
                                                <div>Mes portefeuilles</div>
                                                <img src={IconChevronDown} alt="Chevron Down Icon" />
                                            </div>
                                            {
                                                this.state.modalMyWallet && (
                                                    <div
                                                        className={`container-modal modal-my-wallet show`}
                                                        ref={this.modalRef}
                                                        // onClick={this.handleOverlayClick}
                                                    >
                                                        <MyWallet addWallet={this.handleAddWallet} refreshData={this.refreshData} />
                                                    </div>
                                                )
                                            }
                                        </>
                                )
                            }
                            {this.props.iconRefresh &&
                                <div className="icon-navbar refresh">
                                    <Button rotateInf={this.props.buttonSync} onClick={this.syncData} disabled={this.props.buttonSync}>
                                        <img src={IconRefresh} alt="Refresh Icon" />
                                    </Button>
                                </div>
                            }
                            {
                                !this.state.isMobile && (
                                    <>
                                        <div className="icon-navbar">
                                            <button onClick={this.handleNotification}>
                                                <img src={IconNotification} alt="Notification Icon" />
                                            </button>
                                            {
                                                this.state.modalNotification && (
                                                    <div
                                                        className={`container-modal notification show`} 
                                                        ref={this.modalRef}
                                                        // onClick={this.handleOverlayClick}
                                                    >
                                                        <ShowNotification notifications={this.state.notifications} refreshNotification={this.refreshNotification} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="icon-navbar">
                                            <button onClick={this.handleNavbarAccount}>
                                                <img src={IconUser} alt="User Icon" />
                                            </button>
                                            {
                                                this.state.modalNavbarAccount && (
                                                    <div
                                                        className={`container-modal setting show`} 
                                                        ref={this.modalRef}
                                                        // onClick={this.handleOverlayClick}
                                                    >
                                                        <SettingAccount listSetting={dropdownItemsAccount} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </>
                                )
                            }
                            {this.state.isMobile && (
                                <div className="toggle-menu" onClick={this.toggleMenu}>
                                    <img src={IconMenuHamburger} alt="Icon Menu Hamburger" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* AddWallet ModalBottom */}
                <ModalBottom
                    type={"content"}
                    items={<AddWallet type="content" />}
                    isOpen={this.state.isOpenAddWallet}
                    onClose={this.handleCloseModalBottom}
                />

                {/* NavbarList ModalBottom */}
                <ModalBottom
                    type={"list"}
                    items={dropdownItems}
                    isOpen={this.state.isOpenNavbar}
                    onClose={this.handleCloseModalBottom}
                />

                {/* NavbarList Account ModalBottom */}
                <ModalBottom
                    type={"list"}
                    items={dropdownItemsAccount}
                    isOpen={this.state.isOpenNavbarAccount}
                    onClose={this.handleCloseModalBottomTwo}
                />

                {/* NotificationList ModalBottom */}
                <ModalBottom
                    type={"content"}
                    items={<ShowNotification type="content" notifications={this.state.notifications} refreshNotification={this.refreshNotification} />}
                    isOpen={this.state.isOpenNavbarNotification}
                    onClose={this.handleCloseModalBottomTwo}
                />
            </>
        );
    }
}

export default Navbar;